<template>
  <TTView>
    <VRow>
      <VCol>
        TTToolbar
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <div>
          <TTToolbar>
            <TTToolbarTitle>123</TTToolbarTitle>
          </TTToolbar>
        </div>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>

export default {
  name: 'Toolbar',
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
